<template>
  <v-app>
    <v-app-bar app color="primary" dark flat>
      <div class="d-flex align-center">
        <v-img
          :src="require('./assets/StrasCoree.png')"
          class="my-3"
          contain
          height="55"
          width="100"
          transition="scale-transition"
          @click="gotoLoginMethod"
        />
        <v-img
          :src="require('./assets/inscription.png')"
          class="my-3"
          contain
          height="55"
          width="100"
          transition="scale-transition"
          @click="gotoLoginMethod"
        />

        <!--        <v-toolbar-title>Stras'Corée Inscription</v-toolbar-title> -->
      </div>

      <v-spacer></v-spacer>

      <div v-if="loggedIn == true">
        <v-dialog transition="dialog-top-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <!-- <v-btn @click="logoutMethod" text> -->
            <v-btn v-on="on" v-bind="attrs" text>
              <span class="mr-2">Déconnexion</span>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark>Déconnexion</v-toolbar>
              <v-card-text>
                <div class="text-h4 pa-3">
                  Les données non sauvegardées seront perdues
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Annuler</v-btn>
                <v-btn text @click="logoutMethod">Déconnecter</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <div v-else>
        <v-btn @click="gotoLoginMethod" text>
          <v-icon>mdi-login</v-icon>
          <span class="mr-2">Connexion</span>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <Form
        ref="formioForm"
        :src="domain + url"
        :options="{
          language: 'fr',
          i18n: {
            fr: {
              submit: 'Enregistrer',
              next: 'Suivant',
              cancel: 'Annuler',
              previous: 'Précédent',
              Size: 'Taille',
              'File Name': 'Nom du fichier',
              'Token Expired': 'Session expirée'
            },
          },
        }"
        @submitButton="submitButtonMethod"
        @beforeSubmit="beforeSubmitMethod"
        @submit="submitMethod"
        @submitDone="submitDoneMethod"
        @initialized="initializedMethod"
        @resetpassword="resetPasswordMethod"
        @render="renderMethod"
        @editGridAddRow="editGridAddRowMethod"
        @editGridSaveRow="editGridSaveRowMethod"
        @registerEvent="registerMethod"
        @resetPasswordEvent="resetPasswordMethod"
        @gotoLoginEvent="gotoLoginMethod"
        @copyFamilyToMemberEvent="copyFamilyToMemberMethod"
        @change="changeMethod"
        @redraw="redrawMethod"
        @formLoad="formLoadMethod"
        @wizardPageSelected="wizardPageSelectedMethod"
        @nextPage="nextPageMethod"
        @pagesChanged="pagesChangedMethod"
      />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { Form } from "vue-formio";
import Formio from "formiojs/Formio";
//import FormioUtils from "formiojs/utils/formUtils";
import {getComponent} from 'formiojs/utils/formUtils.js';
import { parse, stringify, toJSON, fromJSON } from "flatted";

export default Vue.extend({
  name: "App",

  components: {
    Form,
  },
  data: () => ({
    loggedIn: false,
    rowsInited: false,
    module: "login",
    domain: "https://ins.strascoree.fr/api",
    url: "/user/login",
    forms: [],
    urlTable: [
      { url: "/user/login", table: "user" },
      { url: "/family", table: "family" },
      { url: "/membersform", table: "member" },
      { url: "/familyeditgrid", table: "familyeditgrid" },
    ],
    token: "",
    debounceFCpyTimer: 0,
  }),
  methods: {
    // UTILS
    loggedInMsg() {
      return this.$data.loggedIn ? "Déconnexion" : "Connexion";
    },
    findUrlTable(url: boolean, value: string) {
      let result = "";
      for (var i = 0, iLen = this.$data.urlTable.length; i < iLen; i++) {
        if (url) {
          if (this.$data.urlTable[i].url == value) {
            result = this.$data.urlTable[i].table;
            break;
          }
        } else {
          if (this.$data.urlTable[i].table == value) {
            result = this.$data.urlTable[i].url;
            break;
          }
        }
      }
      console.log("findUrlTable(" + url + ", " + value + " returns " + result);
      return result;
    },
    loadSubmissions(form: string): any {
      const formio = new Formio(this.$data.domain + "/" + form);
      console.log(formio);
      formio
        .loadSubmissions({
          params: {},
        })
        .then((submissions: { data: any }[]) => {
          console.log("loadSubmissions" + JSON.stringify(submissions[0].data));
          console.log(submissions[0].data);
          this.$refs.formioForm.formio.setSubmission(submissions[0]);
          return submissions[0].data;
        });
    },
    fillFormMethod() {
      this.loadSubmissions(this.$data.urlTable[this.$data.url]);
    },
    // GET FORM METHODS
    getFormByName(formName: string) {
      const result = this.$data.forms.find(
        (form: any) => form.name === formName
      );
      console.log("getFormByName");
      console.log(result);
      return result;
    },
    getFormById(formId: string): object {
      const result = this.$data.forms.find(
        (form: any) => form._id === formId
      );
      console.log("getFormById result is: " + JSON.stringify(result));
      return result;
    },
    getFormId(formName: string) {
      return this.getFormByName(formName)._id;
    },
    getFormPath(formName: string) {
      return this.getFormByName(formName).name;
    },

    formIdsLoaded() {
      return this.$data.forms[0] != undefined;
    },
    fetchFormIds() {
      if (this.formIdsLoaded()) {
        console.log("forms already fetched");
        return;
      }
      // Fetch form ids
      var formio = new Formio(this.$data.domain);
      formio
        .loadForms({
          params: {
            select: "name, path, id",
          },
        })
        .then((forms: object[]) => {
          this.$data.forms = JSON.parse(JSON.stringify(forms));
          console.log(this.$data.forms);
        });
    },
    loadFamilyForm() {
      console.log("loadFamilyForm");
      const formio = new Formio(this.$data.domain + "/familyeditgrid");
      let curForm = this.$refs.formioForm.formio;
      formio
        .loadSubmissions({
          params: {},
        })
        .then((submissions: { data: any }[]) => {
          if (submissions != [] && submissions[0] != undefined) {
            let submission = submissions[0];
            curForm
              .setSubmission(submission)
            return submission;
          }
        });
    },
    isLoggedIn() {
     console.log("isLoggedIn");
      this.$data.token = Formio.getToken();
      console.log(this.$data.token);

      this.$data.loggedIn =
        this.$data.token != "" && this.$data.url != "/passwordresetform";
      console.log(this.$data.loggedIn ? "Connected" : "Not connected");
      return this.$data.loggedIn;
    },
    decodeToken(token: string) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    initMembersForm(index:number) {
      if (index == 1 && this.$data.rowsInited == false) {
      let components =
        this.$refs.formioForm.formio.components[1].components[0].components;
        components.forEach((comp) => {
          if (comp.key == "familyMember") {
            this.setMemberComponentsVisibility(comp);
          }
        });
        this.$data.rowsInited == true;
      }
    },
    setMemberComponentsVisibility(component:any) {
      let formComponents = component.formObj.components;
      var legalResponsible = getComponent(formComponents, "legalResponsible");
      var birthDate = getComponent(formComponents, "birthDate");
      var GPPanel = getComponent(formComponents, "GPPanel", true);

      let memberData = component.data.familyMember.data;
      let birthDateValue = memberData.birthDate;
      let familyRoleValue = memberData.familyRole;
      var bDate:any = new Date(birthDateValue);
      var age = (Date.now() - bDate) / 31557600000;
      var isChild =
        (birthDateValue != "" && age < 18) || familyRoleValue == "enfant14Ans";
      var isParent = familyRoleValue == "pere" || familyRoleValue == "mere";

      GPPanel.hidden = true;
      birthDate.hidden = false;
      legalResponsible.hidden = true;

      if (isParent) {
        GPPanel.hidden = true;
        birthDate.hidden = true;
        legalResponsible.hidden = true;
      }
      if (isChild) {
        GPPanel.hidden = false;
        birthDate.hidden = false;
        legalResponsible.hidden = false;
      }
    },

    // EVENTS
    submitMethod(submission: any, saved: boolean) {
      console.log("submitMethod");
      if (this.$data.url == "/passwordresetform") {
        // We have reset the password, go to thank you page
        this.$data.url = "/thankyoupage";
        sessionStorage.setItem("url", "/thankyoupage");
        return submission;
      }

      if (this.$data.url == "/sendpasswordresetform") {
        // We have sent the mail, notify user
        this.$data.url = "/passwordsentnotice";
        sessionStorage.setItem("url", "/passwordsentnotice");
        return;
      }

      if (submission.form == undefined) {
        // No submission? Just return
        console.log("no submission…");
        return;
      }

      // Get form name from id
      var formName = this.getFormById(submission.form).name;
      if (formName == "familyEditGrid") {
        let famille = submission.data.familyForm.data.email;
        // Link members to family before saving
        submission.data.membersEditGrid.forEach((member: any, i: number) => {
          member.familyMember.data.family = famille;
          this.$refs.formioForm.formio
            .setSubmission(submission)
            .catch((error) => console.log("error in setSubmission"));
        });
      }

      this.$data.token = Formio.getToken();
      this.$data.loggedIn = this.$data.token != "";
      if (formName == "user") {
        this.$data.loggedIn = true;
        // Problem with wizard/webform switching
        window.location.reload();
        this.$data.url = "/familyeditgrid";
      }
      sessionStorage.setItem("url", this.$data.url);
      console.log("stored url: " + sessionStorage.getItem("url"));
    },
    initializedMethod() {
      console.log("initializedMethod");
      if (this.$data.url === "/familyeditgrid") {
        var curForm = this.$refs.formioForm.formio;
        var editGrid = curForm.getComponent("membersEditGrid");
        var editRows = editGrid.editRows;
        this.$data.editGridRowsState = [];
        editRows.forEach((row) => this.$data.editGridRowsState.push(row.state));
        console.log(
          "editGrid rows state = " +
            JSON.stringify(this.$data.editGridRowsState)
        );
        console.log("We have familyeditgrid, log submission");
        this.loadFamilyForm();
      }
    },

    logoutMethod() {
      console.log("logout");
      Formio.logout().then(() => {
        this.$data.url = "/user/login";
        sessionStorage.setItem("url", "/user/login");
        location.hash = "";
        // Problem with wizard/webform switching
        window.location.reload();
        console.log("...logged out");
      });
    },

    registerMethod() {
      console.log("register");
      this.$data.url = "/user/register";
      sessionStorage.setItem("url", "/user/register");
    },

    resetPasswordMethod() {
      console.log("resetPasswordMethod");
      this.$data.url = "/sendpasswordresetform";
      sessionStorage.setItem("url", "/sendpasswordresetform");
    },

    gotoLoginMethod() {
      console.log("gotoLoginMethod");
      if (this.$data.url == "/user/login") {
        this.$refs.formioForm.formio.emit("submitButton", {
          state: "submitted",
          component: this.component,
        });
        return;
      }
      this.$data.url = "/user/login";
      sessionStorage.setItem("url", "/user/login");
      location.hash = "";
      window.location.href = location.origin;
      window.location.reload();
    },

    copyFamilyToMemberMethod(submission: object) {
      // Why are those events triggered twice?
      if (this.$data.debounceFCpyTimer) {
        window.clearTimeout(this.$data.debounceFCpyTimer);
        console.log(
          "debounceFCpyTimer " + this.$data.debounceFCpyTimer + " bouncing"
        );
        this.$data.debounceFCpyTimer = 0;
        return;
      }
      this.$data.debounceFCpyTimer = window.setTimeout(
        () =>
          console.log(
            "debounceFCpyTimer " + this.$data.debounceFCpyTimer + " timeout"
          ),
        5000
      );

      console.log("copyFamilyToMemberMethod");
      var familyData = JSON.parse(sessionStorage.getItem("family"));
      var curMemberForm = this.$refs.formioForm.formio;
      curMemberForm.getComponent("address").setValue(familyData.address);
      curMemberForm.getComponent("city").setValue(familyData.city);
      curMemberForm.getComponent("zip").setValue(familyData.zip);
      curMemberForm.getComponent("country").setValue(familyData.country);
      curMemberForm.getComponent("email").setValue(familyData.email);
      curMemberForm.getComponent("phone").setValue(familyData.phone);
      curMemberForm.getComponent("name").setValue(familyData.name);
    },
    wizardStoreFamilyData (index:number, data:any) {
      if (index == 1) {
        sessionStorage.setItem("family", JSON.stringify(data));
      }

    },
    wizardPageSelectedMethod(page, index) {
      console.log("wizardPageSelectedMethod");
      this.wizardStoreFamilyData (index, page._data.familyForm.data);
    },
    nextPageMethod(page, submission) {
      console.log("nextPageMethod");
      this.wizardStoreFamilyData (page.page, page.submission.data.familyForm.data);
    },
    pagesChangedMethod() {
      console.log("pagesChangedMethod");
    },

  },


  // LIFECYCLE
  created() {
    console.log("created");
    // Initialize base urls
    Formio.setBaseUrl(this.$data.domain);
    Formio.setProjectUrl(this.$data.domain);

    // Are we in the reset pawwsord form ?
    const hash = window.location.hash;
    if (Array.from(hash)[0] == "#") {
      var splitParams = hash.split("?");
      var form = splitParams[0].substring(1);
      var token = splitParams[1] && splitParams[1].split("=")[1];
      console.log("passed token " + token);
      // If no temp token, attempt to use saved token
      if (token != "") {
        var parsedToken = this.decodeToken(token);
        var username = parsedToken.username;
        Formio.setToken(token).catch((err) => {
          console.log(err);
        });
        Formio.setUser(username);
      }
      console.log("Requesting form: " + form);
      this.$data.url = "/" + form;
      sessionStorage.setItem("url", this.$data.url);
      return;
    }

    //Fetch forms list if needed
    if (!this.formIdsLoaded()) this.fetchFormIds();

    // If we're authenticated, attempt to restore the last page
    if (this.isLoggedIn()) {
      console.log("loggedIn");
      // Check last session storage
      if (sessionStorage.getItem("url")) {
        let lastUrl = sessionStorage.getItem("url");
        console.log("lastUrl: ", lastUrl);
        this.$data.url = lastUrl;
        if (lastUrl == "/user/login") {
          console.log("trigger logout");
          Formio.logout();
          this.$data.token = "";
        }
      } else {
        console.log("no storage ,trigger logout");
        Formio.logout();
        this.$data.url = "/user/login";
        this.$data.token = "";
      }
    }
    console.log("Mounted url = " + this.$data.url);
  },

  updated() {
    console.log("updated");
    this.fetchFormIds();
    // if back hit
    if (this.$data.url === "/family") {
      this.loadSubmissions("family");
    }
    if (this.$data.url === "/familyeditgrid") {
      console.log("We have familyeditgrid");
      this.loadFamilyForm();
    }
  },
});
</script>



<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: auto 1em auto 1em;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-toolbar__title {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 3.5rem !important;
  font-weight: bold;
}
</style>
