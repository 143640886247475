var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", color: "primary", dark: "", flat: "" } },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("v-img", {
                staticClass: "my-3",
                attrs: {
                  src: require("./assets/StrasCoree.png"),
                  contain: "",
                  height: "55",
                  width: "100",
                  transition: "scale-transition",
                },
                on: { click: _vm.gotoLoginMethod },
              }),
              _c("v-img", {
                staticClass: "my-3",
                attrs: {
                  src: require("./assets/inscription.png"),
                  contain: "",
                  height: "55",
                  width: "100",
                  transition: "scale-transition",
                },
                on: { click: _vm.gotoLoginMethod },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.loggedIn == true
            ? _c(
                "div",
                [
                  _c("v-dialog", {
                    attrs: {
                      transition: "dialog-top-transition",
                      "max-width": "600",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    { attrs: { text: "" } },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v("Déconnexion"),
                                  ]),
                                  _c("v-icon", [_vm._v("mdi-logout")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "default",
                          fn: function (dialog) {
                            return [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { color: "primary", dark: "" } },
                                    [_vm._v("Déconnexion")]
                                  ),
                                  _c("v-card-text", [
                                    _c("div", { staticClass: "text-h4 pa-3" }, [
                                      _vm._v(
                                        " Les données non sauvegardées seront perdues "
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "justify-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              dialog.value = false
                                            },
                                          },
                                        },
                                        [_vm._v("Annuler")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: { click: _vm.logoutMethod },
                                        },
                                        [_vm._v("Déconnecter")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2346968627
                    ),
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.gotoLoginMethod } },
                    [
                      _c("v-icon", [_vm._v("mdi-login")]),
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v("Connexion"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("Form", {
            ref: "formioForm",
            attrs: {
              src: _vm.domain + _vm.url,
              options: {
                language: "fr",
                i18n: {
                  fr: {
                    submit: "Enregistrer",
                    next: "Suivant",
                    cancel: "Annuler",
                    previous: "Précédent",
                    Size: "Taille",
                    "File Name": "Nom du fichier",
                    "Token Expired": "Session expirée",
                  },
                },
              },
            },
            on: {
              submitButton: _vm.submitButtonMethod,
              beforeSubmit: _vm.beforeSubmitMethod,
              submit: _vm.submitMethod,
              submitDone: _vm.submitDoneMethod,
              initialized: _vm.initializedMethod,
              resetpassword: _vm.resetPasswordMethod,
              render: _vm.renderMethod,
              editGridAddRow: _vm.editGridAddRowMethod,
              editGridSaveRow: _vm.editGridSaveRowMethod,
              registerEvent: _vm.registerMethod,
              resetPasswordEvent: _vm.resetPasswordMethod,
              gotoLoginEvent: _vm.gotoLoginMethod,
              copyFamilyToMemberEvent: _vm.copyFamilyToMemberMethod,
              change: _vm.changeMethod,
              redraw: _vm.redrawMethod,
              formLoad: _vm.formLoadMethod,
              wizardPageSelected: _vm.wizardPageSelectedMethod,
              nextPage: _vm.nextPageMethod,
              pagesChanged: _vm.pagesChangedMethod,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }